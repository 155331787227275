import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { ReactElement, useState } from 'react';
import { useArticle, useCategory } from '../ContextProviders/AppContext';
import { useAuth } from '../ContextProviders/Auth';
import { PlatformContentFrame } from '../PlatformContentFrame/PlatformContentFrame';
import { IconButton } from '../Buttons/Buttons';
import './ArticleBodyPublish.scss';
import { ArticleBreadcrumbs } from './ArticleBreadcrumbs';
import { useLocalization } from '../ContextProviders/LocalizationContext';
import ArticleInfo from '../ArticleInfo/ArticleInfo';
import { useConcreteProject } from '../ContextProviders/ProjectContext';

interface ArticleBodyProps {
  id: string;
}
export const ArticleBodyPublish = ({ id }: ArticleBodyProps): ReactElement => {
  const article = useArticle(id);
  const localization = useLocalization();
  const category = useCategory(article?.category);
  const auth = useAuth();
  const config = useConcreteProject();
  const [showArticleInfo, setShowArticleInfo] = useState(false);

  if (!article || !category) return <></>;

  return (
    <article>
      <ArticleBreadcrumbs id={id} />
      {config.enableTagSearch && (
        <div className="edit-view-cancel-buttons">
          <IconButton
            onClick={() => setShowArticleInfo(!showArticleInfo)} // Toggle visibility
            theme="dark"
            icon={showArticleInfo ? faEyeSlash : faEye}
            text={
              showArticleInfo
                ? localization.strings.article.articleInfo.hideTagEditor
                : localization.strings.article.articleInfo.showTagEditor
            }
          />
        </div>
      )}
      {config.enableTagSearch && showArticleInfo && <ArticleInfo id={id} editing={false} />}
      <div className="article-body">
        <PlatformContentFrame body={article.content} key={id} articleId={article.fId} editTags={true} />
      </div>
      {auth.isAdmin && article.lastUpdated && article.lastUpdatedBy ? (
        <div className="last-edited">
          {`${localization.strings.publish.titles.lastUpdatedBy}: ${article.lastUpdatedBy}, ${article.lastUpdated
            .toDate()
            .toLocaleDateString('sv-SE')}`}
        </div>
      ) : article.lastUpdated ? (
        <div className="last-edited">
          {`${localization.strings.publish.titles.lastUpdated}: ${article.lastUpdated
            .toDate()
            .toLocaleDateString('sv-SE')}`}
        </div>
      ) : (
        ''
      )}
    </article>
  );
};
